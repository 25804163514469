<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref, onMounted } from 'vue';
import { trans } from 'laravel-vue-i18n';

import { useToast } from 'vue-toastification';
const toast = useToast();

const formTemplates = ref([]);
const loading = ref(true);

import { useFormStore } from '@/stores/form';
const { getFormableTemplates } = useFormStore();

onMounted(async () => {
    try {
        const resp = await getFormableTemplates({
            type: props.type // the class that is "formable" - or "linkable" to a template
        });

        formTemplates.value = resp.data;

    } catch (_) {
        toast.error(trans('label.error'));
    } finally {
        loading.value = false;
    }
});

const emit = defineEmits(['close-modal']);

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
});

const selectTemplate = (template) => {
    emit('close-modal', template);
};

</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: 'Select', what: 'Form Template'}) }}</template>
    <DataTable
        
        v-if="formTemplates && !loading"
        :data-table-items="formTemplates"
        :use-data-server="false"
        :headers="[
            { title: $t('label.name'), key: 'name', value: item => `${item.title}`, sortable: false },
            { title: $t('label.category'), key: 'category', value: item => `${item?.type?.name ?? ''}` , sortable: false },
            { title: $t('label.labels'), key: 'labels', value: item => item.labels.map(l => l.name).join(', ') , sortable: false },
        ]"
        :actions="[
            {
                title: $t('label.select'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => selectTemplate(item),
            },
        ]" />

    <div v-if="loading" class="flex gap-4">
        <v-progress-circular
            color="primary"
            indeterminate />
        <div class="self-center font-bold text-lg">{{ $t('label.loading') }}</div>
    </div>
</ModalWrapper>
</template>
