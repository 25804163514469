<script setup>
import { ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useProgramStore } from '@/stores/program';
import DocumentManager from '@/components/Documents/DocumentManager.vue';

// Stores
const { updateProgramSafety } = useProgramStore();

// Utils
const toast = useToast();

const props = defineProps({
    program: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    safety_plan: '',
    safety_extinguisher_location: '',
    safety_first_aid_location: '',
    safety_fire_exit: '',
    safety_muster_station: '',
});

watch(() => props.program, (program) => {
    form.value = {
        safety_plan: program?.safety_plan ?? '',
        safety_extinguisher_location: program?.safety_extinguisher_location ?? '',
        safety_first_aid_location: program?.safety_first_aid_location ?? '',
        safety_fire_exit: program?.safety_fire_exit ?? '',
        safety_muster_station: program?.safety_muster_station ?? '',
    };
}, { immediate: true });

const save = () => {
    updateProgramSafety({
        hash: props.program.hash,
        ...form.value,
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    });
};

</script>

<template>
<div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-2/3 p-2">
    <v-divider class="col-span-full mb-4" />

    <StyledTextArea
        v-model="form.safety_plan"
        :placeholder="$t('label.safety_plan')"
        name="safety_plan"
        :label="$t('label.safety_plan')" />

    <StyledInput
        v-model="form.safety_extinguisher_location"
        :placeholder="$t('label.safety_extinguisher_location')"
        name="safety_extinguisher_location"
        type="text"
        :label="$t('label.fire_extinguisher_location')" />

    <StyledInput
        v-model="form.safety_first_aid_location"
        :placeholder="$t('label.safety_first_aid_location')"
        name="safety_first_aid_location"
        type="text"
        :label="$t('label.first_aid_location')" />

    <StyledInput
        v-model="form.safety_fire_exit"
        :placeholder="$t('label.safety_fire_exit')"
        name="safety_fire_exit"
        type="text"
        :label="$t('label.fire_exit')" />

    <StyledInput
        v-model="form.safety_muster_station"
        :placeholder="$t('label.safety_muster_station')"
        name="safety_muster_station"
        type="text"
        :label="$t('label.muster_station')" />

    <v-divider class="col-span-full mb-4" />
    <StyledButton class="w-1/3" color="primary" @click="save">{{ $t('label.save') }}</StyledButton>

    <v-divider class="col-span-full mb-4" />

    <div class="col-span-full">
        <DocumentManager
            v-if="props.program"
            type="program"
            :documentable="props.program" />
    </div>
</div>
</template>
