<script setup>
import { ref, watch, onMounted } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useProgramStore } from '@/stores/program';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import TimeframeSelector from '@/components/TimeframeSelector';
import { useDateFormatter } from '@/composables/dateFormatter';
import { inject } from 'vue';

// Stores
const { updateProgramDetails } = useProgramStore();
const { programTypes } = storeToRefs(useOptionsStore());
const { formatDOB } = useDateFormatter();
const { searchProgramTasks, updateProgramParticipants } = useProgramStore();
const { programTasks } = storeToRefs(useProgramStore());
const { clientOptions } = storeToRefs(useOptionsStore());
const { activeStaffOptions } = storeToRefs(useOptionsStore());

// Utils
const toast = useToast();
const modals = inject('modals');

const isOffSite = ref(false);
const participants = ref([]);

const props = defineProps({
    program: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    name: '',
    status: null,
    type: null,
    duration: '',
    day_of_week: '',
    start_date: '',
    end_date: '',
    location_name: '',
    location_address: '',
    location_city: '',
    location_postal: '',
    description: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    timeframes: [],
    staff: [],
});

const params = ref({
    term: '',
    page: 1,
    per_page: 10,
    status: null, 
    type: null, 
});

watch(() => props.program, (program) => {
    form.value = {
        name: program?.name ?? '',
        status: program?.is_active ? true : false,
        type: program?.type_id ?? null,
        duration: program?.duration ?? '',
        start_date: program?.start_date ? formatDOB(program?.start_date) : null,
        end_date: program?.end_date ? formatDOB(program?.end_date) : null,
        location_name: program?.location_name ?? '',
        location_address: program?.location_address ?? '',
        location_city: program?.location_city ?? '',
        location_postal: program?.location_postal ?? '',
        description: program?.description ?? '',
        timeframes: program?.timeframes ?? '',
        contact_name: program?.contact_name ?? '',
        contact_email: program?.contact_email ?? '',
        contact_phone: program?.contact_phone ?? '',
        staff: program?.staff?.map(staff => staff.id) ?? [],
    };
}, { immediate: true });

watch(() => form.value.type, () => {
    const offSiteType = programTypes.value.find(type => type.label === 'Off Site');
    isOffSite.value = form.value.type === offSiteType?.value;
}, { immediate: true });

const save = () => {
    updateProgramDetails({
        hash: props.program.hash,
        ...form.value,
        is_active: form.value.status,
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    });
};

onMounted(() => {
    if (props.program) {
        participants.value = props.program.participants?.map(client => client.id);
        searchProgramTasksFunc();
    }
});

const searchProgramTasksFunc = () => {
    searchProgramTasks({
        hash: props.program.hash,
        ...params.value,
    });
};

const openProgramTasksModal = (programTask = null) => {
    modals.show('ProgramTaskModal', {
        props: {
            mode: programTask ? 'Edit' : 'Create',
            task: programTask ? programTask : {},
            program: props.program,
        }
    }).then(() => {
        searchProgramTasksFunc();
    });
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchProgramTasksFunc();
};

const sendUpdateProgramParticipants = () => {
    updateProgramParticipants({
        hash: props.program.hash,
        participants: participants.value,
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    });
};

// Utility function to truncate text
const truncateText = (text, length = 30) => {
    if (text.length <= length) {
        return text;
    }
    return text.substring(0, length) + '...';
};

</script>

<template>
<div class="grid grid-cols-1 sm:grid-cols-2 gap-4 w-2/3 p-2">
    <v-divider class="col-span-full mb-4" />

    <StyledInput
        v-model="form.name"
        :placeholder="$t('label.program_name')"
        name="name"
        type="text"
        :label="$t('label.program_name')" />
    
    <StyledDropdown
        v-model="form.status"
        :placeholder="$t('label.status')"
        :options="[
            { label: $t('label.active'), value: true },
            { label: $t('label.inactive'), value: false },
        ]"
        name="status"
        :label="$t('label.status')" />
        
    <StyledDropdown
        v-model="form.type"
        :placeholder="$t('label.type')"
        :options="programTypes"
        name="type"
        :label="$t('label.type')" />
    
    <StyledInput
        v-model="form.duration"
        disabled
        :placeholder="$t('label.duration')"
        name="duration"
        type="text"
        :label="$t('label.duration')" />

    <StyledDatepicker
        v-model="form.start_date"
        :clearable="false"
        :placeholder="$t('label.start_date')"
        name="start_date"
        :label="$t('label.start_date')" />

    <StyledDatepicker
        v-model="form.end_date"
        :clearable="false"
        :placeholder="$t('label.end_date')"
        name="end_date"
        :label="$t('label.end_date')" />
        
    <StyledAutoComplete
        v-model="form.staff"
        :options="activeStaffOptions"
        name="staff"
        :searchable="true"
        :clearable="true"
        :multiple="true"
        :label="$t('label.responsible_staff')"
        :placeholder="$t('label.responsible_staff')" />

    <div class="col-span-full text-lg mb-4">{{ $t('label.contact_info') }}</div>
    <StyledInput
        v-model="form.contact_name"
        name="contact_name"
        :label="$t('label.contact_name')"
        required
        :placeholder="$t('label.contact_name')" />    
    <StyledInput
        v-model="form.contact_email"
        name="contact_email"
        email
        :label="$t('label.contact_email')"
        required
        :placeholder="$t('label.contact_email')" />   
    <StyledMask
        v-model="form.contact_phone"
        mask="###-###-####"
        :placeholder="$t('label.contact_phone')"
        name="contact_phone"
        :label="$t('label.contact_phone')" />

    <v-divider v-if="isOffSite" class="col-span-full mb-4" />
    <div v-if="isOffSite" class="col-span-full text-lg mb-4">{{ $t('label.timeframes') }}

        <TimeframeSelector 
            v-if="props.program.timeframes"
            class="col-span-full mt-4" 
            :initial-timeframes="props.program.timeframes" 
            @timeframes-updated="form.timeframes = $event" />

    </div>

    <v-divider v-if="isOffSite" class="col-span-full mb-4" />
    <div v-if="isOffSite" class="col-span-full text-lg mb-4">{{ $t('label.location_information') }}

        <StyledInput
            v-model="form.location_name"
            class="mt-4"
            :placeholder="$t('label.location_name')"
            name="location_name"
            type="text"
            :label="$t('label.location_name')" />

        <StyledInput
            v-model="form.location_address"
            :placeholder="$t('label.location_address')"
            name="location_address"
            type="text"
            :label="$t('label.location_address')" />

        <StyledInput
            v-model="form.location_city"
            :placeholder="$t('label.location_city')"
            name="location_city"
            type="text"
            :label="$t('label.location_city')" /> 

        <StyledInput
            v-model="form.location_postal"
            :placeholder="$t('label.location_postal')"
            name="location_postal_code"
            type="text"
            :label="$t('label.location_postal')" />

    </div>
    <v-divider class="col-span-full mb-4" />
    <div class="col-span-full text-lg">{{ $t('label.program_information') }}</div>
    
    <StyledTextArea
        v-model="form.description"
        class="col-span-full"
        :placeholder="$t('label.description')"
        name="description"
        :label="$t('label.description')" />

    <v-divider class="col-span-full mb-4" />
    <StyledButton class="w-1/3" color="primary" @click="save">{{ $t('label.save') }}</StyledButton>

    <v-divider class="col-span-full mb-4" />
    <div class="col-span-full">
        <StyledAutoComplete
            v-model="participants"
            class="mt-5"
            name="participants"
            searchable
            multiple
            chips
            hint="Select participants"
            :options="clientOptions"
            :label="$t('label.add_participant')" 
            @input="sendUpdateProgramParticipants" />

        <div class="flex justify-end pb-4">
            <StyledButton @click="openProgramTasksModal(null)">
                <FontAwesomeIcon icon="plus" class="mr-1" />{{ $t('label.new_task') }}
            </StyledButton>
        </div>

        <DataTable
            v-if="programTasks && programTasks.data"
            :data="programTasks"
            :headers="[
                { title: $t('label.task_name'), key: 'name', value: item => `${item.name}` },
                { title: $t('label.outline'), key: 'outline', value: item => truncateText(item.outline) },
                { title: $t('label.outcome'), key: 'outcome_fulfillment', value: item => truncateText(item.outcome_fulfillment) },
                { title: $t('label.supplies_needed'), key: 'supply_requirement', value: item => truncateText(item.supply_requirement) },
            ]"
            :actions="[
                {
                    title: $t('label.edit'),
                    icon: 'mdi-pencil',
                    action: (item) => openProgramTasksModal(item),
                },
            ]" 
            @table-update="tableUpdate" />
    </div>
</div>

</template>
