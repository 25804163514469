<script setup>
import { ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { inject } from 'vue';
import { useToast } from 'vue-toastification';
import { useOptionsStore } from '@/stores/options';
import { useStaffStore } from '@/stores/staff';
import { storeToRefs } from 'pinia';
// Stores
const { updateStaffAccount } = useStaffStore();
const { roles } = storeToRefs(useOptionsStore());
// Utils
const toast = useToast();
const modals = inject('modals');

const props = defineProps({
    staff: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    //
});

// Restore user account related data
watch(() => props.staff, (staff) => {
    const user = staff?.user;
    if (user) {
        form.value = {
            email: user?.email,
            reset_password: false,
            password: null,
            password_confirmation: null,
            is_active: user?.is_active,
            role_id: user?.roles[0].id,
        };
    } 
}), { immediate: true };

const save = () => {
    updateStaffAccount({
        hash: props.staff.hash,
        email: form.value.email,
        role_id: form.value.role_id,
        is_active: form.value.is_active,
        reset_password: form.value.reset_password,
        password: form.value.password,
        password_confirmation: form.value.password_confirmation,
    }).then(_ => {
        toast.success(trans('label.saved'));
    }).catch(_ => {
        toast.error(trans('label.error'));
    });
};

const triggerPwReset = function() {
    modals.show('ConfirmationModal', {
        props: {
            prompt: trans('label.are_you_sure_trigger_pw_reset')
        }
    }).then(resp => {
        if (resp) {
            toast.error(trans('label.not_implemented'));
        }
    });
};

</script>
<template>
<div class="grid m:grid-cols-1 lg:grid-cols-2 gap-4 w-2/3 p-2">
    <v-divider class="col-span-full mb-4" />
    <div class="col-span-full text-lg mb-4">{{ $t('label.account_details') }}</div>
    <StyledInput
        v-model="form.email"
        :placeholder="$t('label.email')"
        name="email"
        type="email"
        :label="$t('label.email')" />
    <StyledDropdown
        v-model="form.role_id"
        :placeholder="$t('label.role')"
        :options="roles"
        name="role_id"
        :label="$t('label.role')" />
    <StyledRoundSwitch
        v-model="form.is_active"
        color="primary"
        inset
        name="is_active"
        :label="$t('label.active')" />
    <StyledRoundSwitch
        v-model="form.reset_password"
        color="primary"
        inset
        name="reset_password"
        :label="$t('label.reset_password')" />
    <StyledPassword
        v-if="form.reset_password"
        v-model="form.password"
        :placeholder="$t('label.password')"
        name="password"
        :label="$t('label.password')" />
    <StyledPassword
        v-if="form.reset_password"
        v-model="form.password_confirmation"
        :placeholder="$t('label.password_confirmation')"
        name="password_confirmation"
        :label="$t('label.password_confirmation')" />
    <v-divider class="col-span-full mb-4" />
    <StyledButton
        class="w-1/3"
        color="primary"
        @click="save">{{ $t('label.save') }}</StyledButton>
    <StyledButton class="w-2/3" color="warning" @click="triggerPwReset">{{ $t('label.trigger_password_reset') }}</StyledButton>
</div>
</template>
