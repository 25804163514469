import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useStaffStore = defineStore('staff', () => {
    const all = ref({ data: [] });
    const active = ref({});
    const staffRequirements = ref({});

    const reset = () => {
        all.value = { data: [] };
    };

    const search = (params) => {
        return axios.get('/api/v1/staff', { params });
    };

    const store = (params) => {
        return axios.post('/api/v1/staff', params);
    };

    const updateStaffAccount = (params) => {
        return axios.patch(`/api/v1/staff/${params.hash}/account`, params);
    };

    const updateStaffDetails = (params) => {
        return axios.patch(`/api/v1/staff/${params.hash}/details`, params);
    };

    const updateStaffEmployment = (params) => {
        return axios.patch(`/api/v1/staff/${params.hash}/employment`, params);
    };

    const searchStaffRequirements = (params) => {
        return axios.get(`/api/v1/staff/${params.hash}/requirements`, { params });
    };

    const storeStaffRequirement = (formData) => {
        return axios.post(`/api/v1/staff/${formData.get('staff_hash')}/requirements/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    const updateStaffRequirement = (formData) => {
        return axios.post(`/api/v1/staff/${formData.get('staff_hash')}/requirements/${formData.get('requirement_hash')}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    return {
        all,
        reset,
        search,
        store,
        active,
        updateStaffAccount,
        updateStaffDetails,
        updateStaffEmployment,
        updateStaffRequirement,
        staffRequirements,
        storeStaffRequirement,
        searchStaffRequirements
    };
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStaffStore, import.meta.hot));
}
