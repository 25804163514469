<script setup>
import { ref } from 'vue';
import { useTemplateStore } from '@/stores/template';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { router } from '@inertiajs/vue3';

import { 
    getTemplateCreatedBy,
    getTemplateCreatedAt,
    getTemplateVersionInfoEditedBy,
    getTemplateVersionInfoEditedAt
} from '@/utils/templateUtils';

const { search } = useTemplateStore();
const { all } = storeToRefs(useTemplateStore());

const { templateLabelOptions } = storeToRefs(useOptionsStore());

const params = ref({
    term: '',
    status: null,
    page: 1,
    sort_by: null,
    per_page: 10,
});

const searchTemplate = () => {
    search({
        ...params.value,
    });
};

const clearSearch = () => {
    params.value.term = '';
    searchTemplate();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchTemplate();
};

const accessTemplate = (template) => {
    router.visit(`/templates/${template.hash}/details`);
};

const { store } = useTemplateStore();
const createTemplate = () => {
    store().then(resp => {
        accessTemplate(resp.data.template);
    });
};

</script>

<template>
<MainContentLayout>
    <template #breadcrumb><BreadCrumb /></template>
    <template #title>{{ $t('label.template_management') }}</template>
    <template #actions>
        <StyledButton @click="createTemplate"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{  $t('label.new_template') }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchTemplate"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.labels"
            clearable
            multiple
            :placeholder="$t('label.labels')"
            :options="templateLabelOptions"
            name="labels"
            @input="searchTemplate" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.name'), key: 'name', value: item => `${item.title}`, sortable: false },
            { title: $t('label.created_at'), key: 'created_at', value: item => getTemplateCreatedAt(item), sortable: false },
            { title: $t('label.created_by'), key: 'created_by', value: item => getTemplateCreatedBy(item), sortable: false },
            { title: $t('label.last_edited_at'), key: 'last_edited_at', value: item => getTemplateVersionInfoEditedAt(item), sortable: false},
            { title: $t('label.last_edited_by'), key: 'last_edited_by', value: item => getTemplateVersionInfoEditedBy(item), sortable: false },
            { title: $t('label.category'), key: 'category', value: item => `${item?.type?.name ?? ''}` , sortable: false },
            { title: $t('label.labels'), key: 'labels', value: item => item.labels.map(l => l.name).join(', ') , sortable: false },
        ]"
        :actions="[
            {
                title: $t('label.access'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessTemplate(item),
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
