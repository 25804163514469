<script setup>
import { ref, watch, computed } from 'vue';
import { useTemplateStore } from '@/stores/template';
import { useOptionsStore } from '@/stores/options';
import { useFormStore } from '@/stores/form';
import { storeToRefs } from 'pinia';

import WYSIWYG from '@/components/WYSIWYG';

/**********************************************************************
 *      Form Logic
 **********************************************************************/
const { active } = storeToRefs(useTemplateStore());
const { update } = useTemplateStore();

const {
    templateLabelOptions,
    templateTypes,
    templateItemTypes,
    templateItemOptionTypes,
    templateLinkables,
} = storeToRefs(useOptionsStore());

const form = ref({
    title: '',
    type: null,
    linkables: [],
    labels: [],
    items: [],
    roleRules: [],
});

const templateItems = computed(() => {
    return form.value.items.slice().sort((a, b) => a.order - b.order);
});

// Restore the form
watch(
    [
        active,
        templateTypes,
        templateLinkables,
        templateLabelOptions,
        templateItemTypes,
        templateItemOptionTypes,
    ],
    ([
        template,
        types,
        linkables,
        labelOptions,
        itemTypes,
        itemOptionTypes,
    ]) => {
        form.value.hash = template.hash;
        form.value.title = template.title;
        form.value.type = types.find(
            (type) => type.value === template.type_id,
        )?.value;
        form.value.linkables = linkables
            .filter((linkable) =>
                template.linkables
                    .map((l) => l.class_type)
                    .includes(linkable.value),
            )
            .map((l) => l.value);

        // No idea why I need to do this - this throws unless I check here
        // but for everything else I don't need to check. Weird!
        if (template && template.labels) {
            form.value.labels = labelOptions
                .filter((label) =>
                    template.labels.map((l) => l.id).includes(label.value),
                )
                .map((l) => l.value);
        }

        let templateItems = [];

        if (template.items) {
            template.items.forEach((item) => {
                let itemOptions = [];

                item.item_options.forEach((option) => {
                    let tableColumnType = itemOptionTypes.find(
                        (type) => type.value === option.table_column_type?.id,
                    )?.value;

                    itemOptions.push({
                        title: option.title,
                        label: option.label,
                        value: option.value,
                        table_column_type: tableColumnType,
                        requires_response: option.requires_response,
                    });
                });

                templateItems.push({
                    id: item.id,
                    order: item.order,
                    title: item.title,
                    type: itemTypes.find((type) => type.value === item.type_id)
                        ?.value,
                    is_editable: item.is_editable,
                    has_options: item.has_options,
                    is_multiselect: item.is_multiselect,
                    item_options: itemOptions,
                });
            });

            form.value.items = templateItems;
        }
        if (template.role_rules) {
            form.value.roleRules = template.role_rules;
        }
    },
    { immediate: true },
);

const updateItemOrder = (newOrder, item, updatedItemIndex) => {
    const items = [...form.value.items];
    const currentOrder = items[updatedItemIndex].order;

    if (newOrder === currentOrder) {
        return;
    }

    const conflictingItemIndex = items.findIndex((i) => i.order === newOrder);

    if (conflictingItemIndex !== -1) {
        [items[updatedItemIndex].order, items[conflictingItemIndex].order] = [
            items[conflictingItemIndex].order,
            items[updatedItemIndex].order,
        ];
    } else {
        items[updatedItemIndex].order = newOrder;
    }

    items.sort((a, b) => a.order - b.order);
    form.value.items = items;
};

/**********************************************************************
 *      Collapse Logic
 **********************************************************************/

const collapsedIndexes = ref([]);

const isNotCollapsed = (index) => {
    return !collapsedIndexes.value.includes(index);
};

const toggleCollapse = (index) => {
    if (!isNotCollapsed(index)) {
        collapsedIndexes.value = collapsedIndexes.value.filter(
            (i) => i !== index,
        );
    } else {
        collapsedIndexes.value.push(index);
    }
};

const collapseAll = () => {
    collapsedIndexes.value = Array.from(
        { length: form.value.items.length },
        (v, k) => k,
    );
};

const expandAll = () => {
    collapsedIndexes.value = [];
};

/**********************************************************************
 *      Updating
 **********************************************************************/

const updateTemplate = async () => {
    const response = await update({
        hash: form.value.hash,
        title: form.value.title,
        type: form.value.type,
        linkables: form.value.linkables,
        labels: form.value.labels,
        items: form.value.items,
        roleRules: form.value.roleRules,
    });

    const newTemplate = response.data.new_template;

    // Force refresh the page to the new template, set url to http://local.horizon.test/templates/{hash}/details
    window.location.href = `/templates/${newTemplate.hash}/details`;
};

/**********************************************************************
 *      Helpers
 **********************************************************************/

// Handle the content update from the WYSIWYG editor
const handleItemContentUpdate = (content, item) => {
    item.title = content;
};

const itemIsTableType = (item) => {
    if (!templateItemTypes) {
        return false;
    }

    return (
        templateItemTypes.value.find((type) => type.value === item.type)
            ?.label == 'Table'
    );
};

const itemIsTypeOf = (item, types) => {
    if (!templateItemTypes) {
        return false;
    }
    return types.includes(
        templateItemTypes.value.find((type) => type.value === item.type)?.label,
    );
};

const addItem = function () {
    form.value.items.push({
        id: Math.random().toString(36), // Randomly generate an ID, this is used to help remove the items from the list during editing
        order: form.value.items.length + 1,
        title: '',
        type: null,
        is_editable: false,
        has_options: false,
        is_multiselect: false,
        item_options: [],
    });
};

const removeItem = function (item) {
    form.value.items = form.value.items.filter((i) => i.id !== item.id);
};

const addOption = function (item) {
    item.item_options.push({
        title: '',
        label: '',
        value: '',
        table_column_type: null,
        requires_response: false,
    });
};

const removeOption = function (item, option) {
    item.item_options = item.item_options.filter(
        (opt) => opt.title !== option.title,
    );
};

const { generateFormFromTemplate } = useFormStore();

const previewTemplate = async () => {
    const response = await generateFormFromTemplate({
        is_preview: true,
        template_hash: active.value.hash,
        formable_hash: active.value.hash,
        type: 'template',
    });

    window.open(
        `/forms/${response.data.form.hash}/fill-out?is_preview=true`,
        '_blank',
    );
};

/**********************************************************************
 *      Drag and Drop Logic
 **********************************************************************/
const dragstartHandler = (index, event) => {
    // Add the target element's id to the data transfer object
    event.dataTransfer.setData('text/plain', index);
    event.dataTransfer.effectAllowed = 'move';
};

const dragoverHandler = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
};

const dropHandler = (index, event) => {
    event.preventDefault();
    // Get the id of the target and add the moved element to the target's DOM
    const movedItemIndex = event.dataTransfer.getData('text/plain');

    // Change order
    updateItemOrder(
        form.value.items[movedItemIndex].order,
        form.value.items[index],
        index,
    );
};

const isDraggableIconSelected = ref(false);
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <div class="flex justify-between">
            <BreadCrumb :index-override="[0, 2]" />
        </div>
    </template>
    <template #title>{{
        $t("label.template_details", { title: active?.title ?? "" })
    }}</template>
    <div class="mt-4">
        <form
            class="grid grid-cols-1 md:grid-cols-2 gap-4 px-12"
            @submit.prevent="save">
            <StyledInput
                v-model="form.title"
                :label="$t('label.title')"
                name="title" />
            <StyledDropdown
                v-model="form.type"
                :label="$t('label.type')"
                :placeholder="$t('label.type')"
                :options="templateTypes"
                name="type" />
            <StyledDropdown
                v-model="form.linkables"
                multiple
                :label="$t('label.linkables')"
                :placeholder="$t('label.linkables')"
                :options="templateLinkables"
                name="linkables" />
            <StyledDropdown
                v-model="form.labels"
                :label="$t('label.labels')"
                clearable
                multiple
                :placeholder="$t('label.labels')"
                :options="templateLabelOptions"
                name="labels" />
        </form>
        <div class="px-12">
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        {{ $t('label.permissions_configuration') }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div class="px-12 -mb-6">
                            <div
                                class="grid grid-cols-4 font-bold border-b border-gray-light p-2 mt-4 mb-6">
                                <div class="flex items-center">
                                    <div>{{ $t("label.role") }}</div>
                                </div>
                                <div class="flex">
                                    <div>{{ $t("label.can_select") }}</div>
                                    <v-icon
                                        v-tooltip.top="'Determines if the role can see the template from the list of templates when creating a form to fill out'"
                                        class="ml-2 self-center cursor-help"
                                        icon="mdi-information-outline" />
                                </div>
                                <div class="flex">
                                    <div>{{ $t("label.can_view") }}</div>
                                    <v-icon
                                        v-tooltip.top="'Determines if the role can download the form as a PDF after its been filled out'"
                                        class="ml-2 self-center cursor-help"
                                        icon="mdi-information-outline" />
                                </div>
                                <div class="flex">
                                    <div>{{ $t("label.can_edit") }}</div>
                                    <v-icon
                                        v-tooltip.top="'Determines if a role is able to fill out a form'"
                                        class="ml-2 self-center cursor-help"
                                        icon="mdi-information-outline" />
                                </div>
                            </div>
                            <div
                                v-for="roleRule in form.roleRules"
                                :key="`role_rule_${roleRule.hash}`"
                                class="grid grid-cols-4 px-2">
                                <div v-if="roleRule.role.name !== 'super_admin'">
                                    {{ roleRule.role.display_name }}
                                </div>
                                <StyledCheckbox
                                    v-if="roleRule.role.name !== 'super_admin'"
                                    v-model="roleRule.can_select"
                                    :label="$t('label.can_select')"
                                    :name="`can_select_${roleRule.hash}`" />
                                <StyledCheckbox
                                    v-if="roleRule.role.name !== 'super_admin'"
                                    v-model="roleRule.can_view"
                                    :label="$t('label.can_view')"
                                    :name="`can_view_${roleRule.hash}`" />
                                <StyledCheckbox
                                    v-if="roleRule.role.name !== 'super_admin'"
                                    v-model="roleRule.can_edit"
                                    :label="$t('label.can_edit')"
                                    :name="`can_edit_${roleRule.hash}`" />
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <div class="p-4 px-12">
            <v-divider class="my-4" />
            <div class="flex justify-between mt-12">
                <div class="mb-4 text-xl">
                    {{ $t("label.template_items") }}
                </div>
            </div>
            <div class="flex gap-4">
                <StyledButton @click="collapseAll">
                    <v-icon icon="mdi-chevron-up" />
                    {{ $t("label.collapse_all") }}
                </StyledButton>
                <StyledButton @click="expandAll">
                    <v-icon icon="mdi-chevron-down" />
                    {{ $t("label.expand_all") }}
                </StyledButton>
            </div>
            <div v-for="(item, index) in templateItems" :key="item.id">
                <div
                    :draggable="isDraggableIconSelected"
                    class="my-4 pb-6 pt-8 px-12 grid gap-4 border-gray-light border rounded-lg grid-cols-2 lg:grid-cols-4"
                    @dragstart="dragstartHandler(index, $event)"
                    @drop="dropHandler(index, $event)"
                    @dragover="dragoverHandler">
                    <div class="col-span-full mb-4 flex justify-between">
                        <div
                            class="flex gap-x-4 justify-between items-center">
                            <FontAwesomeIcon
                                class="text-primary cursor-move"
                                icon="grip"
                                @mousedown="isDraggableIconSelected = true"
                                @mouseup="isDraggableIconSelected = false" />
                            <div class="text-lg self-center">
                                <v-icon
                                    :icon="
                                        isNotCollapsed(index)
                                            ? 'mdi-chevron-down'
                                            : 'mdi-chevron-up'
                                    "
                                    class="cursor-pointer"
                                    @click="toggleCollapse(index)" />
                                {{ $t("label.item") }} #{{ index + 1 }}
                            </div>
                        </div>
                        <StyledButton
                            class="self-center"
                            color="error"
                            @click="removeItem(item)">{{ $t("label.remove") }}</StyledButton>
                    </div>
                    <StyledDropdown
                        v-if="isNotCollapsed(index)"
                        v-model="item.type"
                        class="col-span-2"
                        :clearable="false"
                        :options="templateItemTypes"
                        :label="$t('label.type')" />
                    <StyledInput
                        v-if="
                            !itemIsTypeOf(item, ['Text Editor']) &&
                                isNotCollapsed(index)
                        "
                        v-model="item.title"
                        class="col-span-2"
                        :label="$t('label.title')"
                        name="title" />
                    <StyledRoundSwitch
                        v-if="
                            itemIsTypeOf(item, ['Text Editor']) &&
                                isNotCollapsed(index)
                        "
                        v-model="item.is_editable"
                        :label="$t('label.is_editable')"
                        color="primary"
                        inset
                        name="is_editable" />
                    <div
                        v-if="
                            itemIsTypeOf(item, [
                                'Single Select',
                                'Multi Select',
                                'Checklist',
                            ]) && isNotCollapsed(index)
                        "
                        class="grid grid-cols-1 col-span-full gap-4 p-4">
                        <div
                            v-if="isNotCollapsed(index)"
                            class="mb-4 text-lg col-span-full">
                            {{ $t("label.options") }}
                        </div>
                        <div v-if="isNotCollapsed(index)">
                            <div
                                v-for="opt in item.item_options"
                                :key="`item_${item.id}_opt_${opt.id}`"
                                class="flex flex-row gap-4">
                                <StyledInput
                                    v-model="opt.title"
                                    :label="$t('label.option_title')"
                                    name="option_title" />
                                <StyledRoundSwitch
                                    v-if="itemIsTypeOf(item, ['Checklist'])"
                                    v-model="opt.requires_response"
                                    :label="$t('label.requires_response')"
                                    color="primary"
                                    inset
                                    name="requires_response" />
                                <div
                                    class="flex gap-2 text-red-500 col-span-2 mt-2 cursor-pointer hover:underline">
                                    <v-icon
                                        icon="mdi-trash-can-outline"
                                        @click="removeOption(item, opt)" />
                                </div>
                            </div>
                        </div>
                        <StyledButton
                            v-if="isNotCollapsed(index)"
                            class="w-40"
                            @click="addOption(item)">
                            <div class="flex items-center justify-center">
                                <v-icon class="mr-2" icon="mdi-plus" />
                                {{ $t("label.add_option") }}
                            </div>
                        </StyledButton>
                    </div>
                    <div
                        v-if="
                            itemIsTableType(item) && isNotCollapsed(index)
                        "
                        class="grid grid-cols-1 col-span-full gap-4 p-4 border border-gray-light shadow-md rounded-lg">
                        <div class="mb-4 text-lg">
                            {{ $t("label.table_columns") }}
                        </div>
                        <div
                            v-for="column in item.item_options"
                            :key="`column_item_${item.id}_column_${column.id}`"
                            class="grid grid-cols-12 gap-4">
                            <StyledInput
                                v-model="column.title"
                                class="col-span-5"
                                :label="$t('label.column_title')"
                                name="column_title" />
                            <StyledDropdown
                                v-model="column.table_column_type"
                                class="col-span-5"
                                :label="$t('label.table_column_type')"
                                :options="templateItemOptionTypes"
                                name="table_column_type" />
                            <div
                                class="flex gap-2 text-red-500 col-span-2 mt-2 cursor-pointer hover:underline">
                                <v-icon
                                    icon="mdi-trash-can-outline"
                                    @click="removeOption(item, column)" />
                            </div>
                        </div>
                        <StyledButton class="w-40" @click="addOption(item)">
                            <div class="flex items-center justify-center">
                                <v-icon class="mr-2" icon="mdi-plus" />
                                {{ $t("label.add_column") }}
                            </div>
                        </StyledButton>
                    </div>
                    <div
                        v-if="itemIsTypeOf(item, ['Text Editor']) && isNotCollapsed(index)"
                        class="col-span-full">
                        <WYSIWYG
                            :initial-content="item.title"
                            @contentUpdate="
                                handleItemContentUpdate($event, item)
                            " />
                    </div>
                </div>
            </div>
            <div
                class="bg-grey-200 my-4 pb-12 pt-12 px-12 border-black border border-dashed rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-100"
                @click="addItem">
                <StyledButton class="flex items-center">
                    <v-icon class="mr-2 text-white" icon="mdi-plus" />
                    {{ $t("label.add_item") }}
                </StyledButton>
            </div>
            <div class="flex gap-4">
                <div
                    class="mt-4 p-4 w-full bg-primary uppercase text-center border rounded-md cursor-pointer hover:shadow-md"
                    @click="updateTemplate">
                    {{ $t("label.save") }}
                </div>
                <div
                    class="mt-4 p-4 w-full bg-primary uppercase text-center border rounded-md cursor-pointer hover:shadow-md"
                    @click="previewTemplate">
                    {{ $t("label.preview") }}
                </div>
            </div>
        </div>
    </div>
</MainContentLayout>
</template>
