import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useDocumentStore = defineStore('document', () => {
    const all = ref({ data: [] });

    const reset = () => {
        all.value = { data: [] };
    };

    const searchTimelineDocuments = (params) => {
        return axios.get(`/api/v1/documents/timeline/${params.hash}`, { params });
    };

    const searchProgramDocuments = (params) => {
        return axios.get(`/api/v1/documents/program/${params.hash}`, { params });
    };

    const searchSupportPlanDocuments = (params) => {
        return axios.get(`/api/v1/documents/support-plan/${params.hash}`, { params });
    };

    const searchSupportPlanGoalEventDocuments = (params) => {
        return axios.get(`/api/v1/documents/support-plan-goal-event/${params.hash}`, { params });
    };

    const searchApplicationDocuments = (params) => {
        return axios.get(`/api/v1/documents/application/${params.hash}`, { params });
    };

    const store = (formData) => {
        return axios.post('/api/v1/documents/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    const archive = (hash) => {
        return axios.delete(`/api/v1/documents/${hash}`);
    };

    return {
        all,
        reset,
        searchTimelineDocuments,
        searchProgramDocuments,
        searchSupportPlanDocuments,
        searchApplicationDocuments,
        searchSupportPlanGoalEventDocuments,
        store,
        archive
    };
});
